// PWAs need HTTPS
if (location.protocol != "https:") location.protocol == "https";

// Reference the serviceWorker.
const serviceWorker = navigator.serviceWorker;

// Register our ServiceWorker script, if serviceWorker is available.
if (serviceWorker) {
    serviceWorker
        .register("/service-worker.js")
        .then(() => console.log("ServiceWorker Registered to the Application!"))
        .catch(() => console.log("Failed to Register the ServiceWorker."));
}

// Create a variable to defer the beforeinstallprompt event.
let beforeInstallEvent;

// Reference the install button from DOM.
const installButton = document.getElementById("install");

// Watch for the beforeinstallevent and defer it.
window.addEventListener("beforeinstallprompt", (event) => {
    event.preventDefault();
    beforeInstallEvent = event;
    installButton.style.display = "block";
});

// Prompt for Installation when install button is clicked.
installButton.addEventListener("click", () => {
    beforeInstallEvent
        .prompt()
        .then((choice) => {
            // Hide the install button as its purpose is over.
            if (choice.outcome == "accepted") {
                installButton.style.display = "none";
            }
        });
});

// Default register
/*
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/service-worker.js')
  .then(function(response) {
     // Service worker registration done
      console.log('Registration Successful', response);
  }, function(error) {
      // Service worker registration failed
      console.log('Registration Failed', error);
})
}
*/

// Register the service worker
if ('serviceWorker' in navigator) {
    // Wait for the 'load' event to not block other work
    window.addEventListener('load', async () => {
      // Try to register the service worker.
      try {
        // Capture the registration for later use, if needed
        let reg;
  
        // Use ES Module version of our Service Worker in development
        if (import.meta.env?.DEV) {
          reg = await navigator.serviceWorker.register('/service-worker.js', {
            type: 'module',
          });
        } else {
          // In production, use the normal service worker registration
          reg = await navigator.serviceWorker.register('/service-worker.js');
        }
  
        console.log('Service worker registered! 😎', reg);
      } catch (err) {
        console.log('😥 Service worker registration failed: ', err);
      }
    });
  }
 
